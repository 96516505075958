<template>
    <div data-scroll :data-scroll-id="name" class="layout-3">
        <div v-observer class="header-titles">
            <!-- <header-title
                :name="header"
                :text-color="titleColor"
                underline-color="#e5b676"
            /> -->
            <text-wipe
                id="layout-3-wipe"
                :header="{ title: header }"
                :color="shaderColor"
                :noneLine="true"
            />
        </div>
        <div v-observer :class="{ dark: !lightMode }" class="content-container">
            <div
                class="item"
                v-for="(item, index) in items"
                v-observer
                :key="index"
            >
                <div
                    class="header"
                    v-html="item.title"
                    :style="{ color: item.color }"
                ></div>
                <div class="description" v-html="item.content"></div>
            </div>
        </div>
    </div>
</template>

<script>
import first from "lodash/first";
// import HeaderTitle from "@/components/custom/HeaderTitle";
import { mapGetters } from "vuex";
import TextWipe from "../../custom/textWipe.vue";

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        },
        config: {
            type: Object,
            default: () => {}
        },
        lightMode: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        }
    },
    components: {
        // HeaderTitle,
        TextWipe
    },
    computed: {
        ...mapGetters({
            device: "resize/geDevice"
        }),
        secondItemSpeed() {
            return this.device.size < 1025 ? 0 : 2;
        },
        firstItemSpeed() {
            return this.device.size < 1025 ? 0 : 0.5;
        },
        items() {
            return first(
                this.data?.list?.map(item =>
                    item?.content?.tabs?.map((cont, index) => ({
                        ...cont,
                        // color: index === 0 ? item.color1 : item.color2
                        color: item.content?.tabs[index]?.color?.color
                    }))
                )
            );
        },
        header() {
            return this.config?.header?.title;
        },
        teaser() {
            return this.config?.header?.teaser;
        },
        wordLength() {
            return this.header?.split(" ")?.length;
        },
        titleColor() {
            return this?.lightMode ? "#0A141C" : "white";
        },
        shaderColor() {
            return [0.039, 0.078, 0.11];
        }
    }
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.layout-3 /deep/ {
    padding: 95px 375px 0;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        padding: 124px 250px 0;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1366px) {
        padding: 144px 200px 0;
    }
    @media only screen and (max-width: 1365px) and (min-width: 1024px) {
        padding: 144px 47px 0;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding: 144px 47px 0;
    }
    @media only screen and (max-width: 767px) {
        padding: 89px 16px 0;
    }

    .header-title {
        font-size: 46px;
        font-weight: normal;
        line-height: 52px;

        span {
            color: #e5b676;
        }

        @media only screen and (max-width: 1365px) and (min-width: 1024px) {
            font-size: 34px;
        }

        &:nth-child(2) {
            margin-top: 5px;
        }

        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            font-size: 31px;
            line-height: 46px;
        }
        @media only screen and (max-width: 767px) {
            font-size: 21px;
            line-height: 40px;
        }
    }

    .header-titles {
        text-align: center;

        &.observed {
            .title {
                transform: translateY(0);
            }
        }

        .title {
            font-size: 55px;
            fill: #0a141c;
            max-width: 1170px;
            text-align: center;
            margin: 0 auto;
            transition: transform $ease-out 0.6s;
            transform: translateY(150px);

            @media only screen and (max-width: 1440px) and (min-width: 1024px) {
                font-size: 66px;
                max-width: 900px;
                transform: translateY(100px);
            }

            @media only screen and (max-width: 1023px) and (min-width: 768px) {
                font-size: 46px;
                max-width: 650px;
                transform: translateY(60px);
            }

            @media only screen and (max-width: 767px) {
                font-size: 21px;
                max-width: 300px;
                transform: translateY(40px);
            }

            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                    transition-delay: $i/10 + s;
                }
            }

            span {
                color: #e5b676;
            }
        }
    }

    .content-container {
        margin-top: 101px;
        display: flex;
        align-items: flex-start;
        text-align: left;
        grid-gap: 30px;

        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            margin-top: 75px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            margin-top: 50px;
        }
        @media only screen and (max-width: 1023px) {
            flex-direction: column;
        }
        @media only screen and (max-width: 767px) {
            margin-top: 34px;
        }

        &.dark {
            .description {
                color: white !important;

                h1,
                h2,
                h3,
                p,
                span,
                div {
                    color: white !important;
                }
            }
        }

        .item {
            flex: 50%;
            flex-direction: column;
            display: flex;

            &.observed {
                .header,
                .description {
                    transform: translateY(0);

                    span {
                        transform: translateY(0);
                    }
                }
            }

            .header {
                display: block;
                text-align: left;
                font-weight: bold;
                font-size: 21px;
                transform: translateY(30px);
                transition: transform 0.6s 0.1s $ease-out;

                span {
                    font-size: 55px;
                    display: block;
                    margin-bottom: 10px;
                    transform: translateY(30px);
                    transition: transform 0.6s 0.2s $ease-out;

                    @media only screen and (max-width: 1365px) {
                        font-size: 34px;
                    }
                }
            }

            .description {
                margin-top: 10px;
                font-weight: normal;
                transform: translateY(30px);
                transition: transform 0.6s 0.25s $ease-out;
                font-weight: normal;
                color: #0a141c;
                font-size: 21px;
                font-family: "Noto-Sans", "Noto-Sans-georgian";

                h1,
                h2,
                h3,
                p,
                span,
                div {
                    font-weight: normal;
                    color: #0a141c;
                    font-size: 21px;
                    font-family: "Noto-Sans", "Noto-Sans-georgian";
                    transition: transform 0.4s $ease-out;
                }
            }
        }
    }
}
</style>
